import { render, staticRenderFns } from "./pager.vue?vue&type=template&id=d6e6cebc"
import script from "./pager.vue?vue&type=script&lang=js"
export * from "./pager.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/erfgoedhuis-zh/erfgoedhuis-zh/HumanDigital.Website/Frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d6e6cebc')) {
      api.createRecord('d6e6cebc', component.options)
    } else {
      api.reload('d6e6cebc', component.options)
    }
    module.hot.accept("./pager.vue?vue&type=template&id=d6e6cebc", function () {
      api.rerender('d6e6cebc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "scripts/overview/pager.vue"
export default component.exports